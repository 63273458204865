<template>
	<div class="dropdown-box nav-dropdowns">
		<div v-if="type === 'product'">
			<div class="columns">
				<div class="column is-paddingless is-6">
					<router-link
						to="/register"
						class="navbar-item icon"
						:title="$t('TRADE')"
					>
						<div class="icon">
							<img
								src="@/assets/img/navbar/trade_logo.svg"
								alt="Morpher Trade Logo"
							/>
						</div>
						<div class="text">
							<p class="link-font">{{ $t("TRADE") }}</p>
							<p class="sub-text">
								{{ $t("TRADE_DESC") }}
							</p>
						</div>
					</router-link>
					<router-link
						to="/wallet"
						class="navbar-item icon"
						:title="$t('WALLET')"
					>
						<div class="icon">
							<b-icon
								icon="fas fa-wallet"
								pack="fas"
								class="link-icon is-blue"
							/>
						</div>
						<div class="text">
							<p class="link-font">{{ $t("WALLET") }}</p>
							<p class="sub-text">
								{{ $t("WALLET_DESC") }}
							</p>
						</div>
					</router-link>
					<!-- <router-link
						to="/arena"
						class="navbar-item icon"
						:title="$t('ARENA')"
					>
						<div class="icon">
							<img
								src="@/assets/img/navbar/eth_dex_logo.svg"
								alt="Morpher DEX Logo"
							/>
						</div>
						<div class="text">
							<p class="link-font">{{ $t("ARENA") }}</p>
							<p class="sub-text">
								{{ $t("ARENA_DESC") }}
							</p>
						</div>
					</router-link> -->
					<div class="tiny-text">
						<p>
							<b-icon icon="fas fa-info-circle" pack="fas" size="is-small" />
							{{ $t("STOCKS_MARKETS_DISCLAMER") }}
						</p>
					</div>
				</div>
				<div class="column is-1 is-paddingless" />
				<div class="column is-paddingless is-5">
					<p class="nav-menu-text">{{ $t("BLOCKCHAIN_TOOLS") }}</p>
					<router-link
						to="/stats"
						class="navbar-item icon"
						:title="$t('MPH_PROTOCOL_STATS')"
					>
						<div class="icon">
							<b-icon icon="fas fa-chart-bar" pack="fas" class="link-icon" />
						</div>
						<div class="text">
							<p class="link-font">
								{{ $t("MPH_PROTOCOL_STATS") }}
							</p>
							<p class="sub-text">
								{{ $t("MPH_PROTOCOL_STATS_DESC") }}
							</p>
						</div>
					</router-link>
					<router-link
						to="/scan"
						class="navbar-item icon"
						:title="$t('SIDECHAIN_EXPLORER')"
					>
						<div class="icon">
							<b-icon icon="fas fa-dice-d6" pack="fas" class="link-icon" />
						</div>
						<div class="text">
							<p class="link-font">{{ $t("SIDECHAIN_EXPLORER") }}</p>
							<p class="sub-text">
								{{ $t("SIDECHAIN_EXPLORER_DESC") }}
							</p>
						</div>
					</router-link>
				</div>
				<div class="column is-1 is-paddingless" />
			</div>
		</div>

		<div v-else-if="type === 'token'">
			<div class="columns">
				<div class="column is-paddingless is-7">
					<!-- <router-link
						to="/airdrop"
						class="navbar-item icon"
						:title="$t('AIRDROP')"
					>
						<div class="icon">
							<b-icon
								icon="fas fa-parachute-box"
								pack="fas"
								class="link-icon"
							/>
						</div>
						<div class="text">
							<p class="link-font">{{ $t("AIRDROP") }}</p>
							<p class="sub-text" v-html="$t('AIRDROP_DESC')" />
						</div>
					</router-link> -->
					<a
						:href="$t('WHAT_IS_MPH_SUPPORT_LINK')"
						class="navbar-item icon"
						:title="$t('WHAT_IS_MPH')"
					>
						<div class="icon">
							<b-icon icon="fab fa-ethereum" pack="fab" class="link-icon" />
						</div>
						<div class="text">
							<p class="link-font" v-html="$t('WHAT_IS_MPH')" />
							<p class="sub-text">
								{{ $t("WHAT_IS_MPH_DESC") }}
							</p>
						</div>
					</a>
					<a
						:href="$t('TOKENOMICS_SUPPORT_LINK')"
						class="navbar-item icon"
						:title="$t('TOKENOMICS')"
					>
						<div class="icon">
							<b-icon icon="fas fa-coins" pack="fas" class="link-icon" />
						</div>
						<div class="text">
							<p class="link-font">
								{{ $t("TOKENOMICS") }}
							</p>
							<p class="sub-text">
								{{ $t("TOKENOMICS_DESC") }}
							</p>
						</div>
					</a>
				</div>
				<div class="column is-1 is-paddingless" />
				<div class="column is-paddingless is-6">
					<p class="nav-menu-text">{{ $t("EXCHANGES") }}</p>
					<a
						href="https://v2.info.uniswap.org/token/0x6369c3dadfc00054a42ba8b2c09c48131dd4aa38"
						class="navbar-item icon"
						target="_blank"
						rel="nofollow noopener noreferrer"
						:title="$t('UNISWAP')"
					>
						<div class="icon">
							<img src="@/assets/img/navbar/uniswap.svg" alt="Uniswap Logo" />
						</div>
						<div class="text">
							<p class="link-font">
								{{ $t("UNISWAP") }}
							</p>
							<p class="sub-text" v-html="$t('UNISWAP_DESC')" />
						</div>
					</a>
					<a
						href="https://www.gate.io/trade/MPH_USDT"
						class="navbar-item icon"
						target="_blank"
						rel="nofollow noopener noreferrer"
						:title="$t('GATEIO')"
					>
						<div class="icon">
							<img src="@/assets/img/navbar/gateio.svg" alt="Gate.io Logo" />
						</div>
						<div class="text">
							<p class="link-font">
								{{ $t("GATEIO") }}
							</p>
							<p class="sub-text" v-html="$t('GATEIO_DESC')" />
						</div>
					</a>
					<a
						href="https://coinmarketcap.com/currencies/morpher/"
						target="_blank"
						class="navbar-item icon"
						rel="noopener noreferrer"
						:title="$t('COINMAKRETCAP')"
					>
						<div class="icon">
							<img
								src="@/assets/img/navbar/coinmarketcap.svg"
								alt="CoinMarkteCap Logo"
							/>
						</div>
						<div class="text">
							<p class="link-font">
								{{ $t("COINMAKRETCAP") }}
							</p>
							<p class="sub-text" v-html="$t('COINMAKRETCAP_DESC')" />
						</div>
					</a>
				</div>
			</div>
		</div>

		<div v-else-if="type === 'learn'">
			<div class="columns">
				<div class="column is-paddingless is-6">
					<!-- <router-link
						to="/whats-new"
						class="navbar-item text"
						:title="$t('CHANGELOG_TITLE')"
					>
						<p class="link-font">
							{{ $t("CHANGELOG_TITLE") }}
						</p>
						<p class="sub-text">
							{{ $t("CHANGELOG_NAV_DESC") }}
						</p>
					</router-link> -->
					<a href="/blog" class="navbar-item text" :title="$t('BLOG')">
						<div class="text">
							<p class="link-font">
								{{ $t("BLOG") }}
							</p>
							<p class="sub-text">
								{{ $t("BLOG_DESC") }}
							</p>
						</div>
					</a>
					<a
						:href="whitepaperLink || '/docs/morpher_whitepaper.pdf'"
						class="navbar-item text"
						target="_blank"
						:title="$t('WHITEPAPER')"
					>
						<div class="text">
							<p class="link-font">
								{{ $t("WHITEPAPER") }}
							</p>
							<p class="sub-text">
								{{ $t("WHITEPAPER_DESC") }}
							</p>
						</div>
					</a>
					<div class="navbar-item">
						<div class="whitepaper-links">
							<a href="/docs/morpher_whitepaper.pdf">
								English
							</a>
							|
							<a href="/docs/morpher_whitepaper_russian.pdf">
								Pусский
							</a>
							|
							<a href="/docs/morpher_whitepaper_chinese.pdf">
								普通话
							</a>
						</div>
					</div>
				</div>
				<div class="column is-paddingless is-1" />
				<div class="column is-paddingless is-4">
					<a
						href="https://support.morpher.com"
						class="navbar-item"
						:title="$t('HELP_CENTER')"
					>
						<div class="text">
							<p class="link-font">
								{{ $t("HELP_CENTER") }}
							</p>
							<p class="sub-text">
								{{ $t("HELP_CENTER_DESC") }}
							</p>
						</div>
					</a>
					<a
						:href="$t('GETTING_STARTED_SUPPORT_LINK')"
						class="navbar-item is-size-6"
						:title="$t('GETTING_STARTED')"
					>
						<div class="social-link">
							<p>
								{{ $t("GETTING_STARTED") }}
							</p>
						</div>
					</a>
					<a
						:href="$t('SUPPORTED_COUNTRIES_SUPPORT_LINK')"
						class="navbar-item is-size-6"
						:title="$t('SUPPORTED_COUNTRIES')"
					>
						<div class="social-link">
							<p>
								{{ $t("SUPPORTED_COUNTRIES") }}
							</p>
						</div>
					</a>
					<a
						:href="$t('ACCOUNT_AND_LOGIN_SUPPORT_LINK')"
						class="navbar-item is-size-6"
						:title="$t('ACCOUNT_AND_LOGIN')"
					>
						<div class="social-link">
							<p>
								{{ $t("ACCOUNT_AND_LOGIN") }}
							</p>
						</div>
					</a>
				</div>
				<div class="column is-paddingless is-1" />
				<div class="column is-paddingless is-4">
					<p class="nav-menu-text mt-10">{{ $t("COMMUNITY") }}</p>
					<!-- <a
						href="https://discord.gg/CBxuAhPSCj"
						class="navbar-item"
						target="_blank"
						rel="nofollow noopener noreferrer"
						:title="$t('DISCORD')"
					>
						<div class="social-link">
							<p>
								{{ $t("DISCORD") }}
							</p>
						</div>
					</a> -->
					<a
						href="https://twitter.com/morpher_io"
						class="navbar-item is-size-6"
						target="_blank"
						rel="nofollow noopener noreferrer"
						:title="$t('TWITTER')"
					>
						<div class="social-link">
							<p>
								{{ $t("TWITTER") }}
							</p>
						</div>
					</a>
					<!-- <a
						href="https://vk.com/morpher"
						class="navbar-item is-size-6"
						target="_blank"
						rel="nofollow noopener noreferrer"
						:title="$t('VK')"
					>
						<div class="social-link">
							<p>
								{{ $t("VK") }}
							</p>
						</div>
					</a> -->
					<a
						href="https://www.linkedin.com/company/morpher"
						target="_blank"
						rel="nofollow noopener noreferrer"
						:title="$t('LINKEDIN')"
						class="navbar-item is-size-6"
					>
						<div class="social-link">
							<p>
								{{ $t("LINKEDIN") }}
							</p>
						</div>
					</a>
					<a
						href="https://github.com/Morpher-io/"
						target="_blank"
						rel="nofollow noopener noreferrer"
						:title="$t('GITHUB')"
						class="navbar-item is-size-6"
					>
						<div class="social-link">
							<p>
								{{ $t("GITHUB") }}
							</p>
						</div>
					</a>
				</div>
			</div>
		</div>

		<div v-else-if="type === 'company'">
			<div class="column is-paddingless is-narrow">
				<router-link to="/about" class="navbar-item" :title="$t('ABOUT_US')">
					<div class="text">
						<p class="link-font">
							{{ $t("ABOUT_US") }}
						</p>
					</div>
				</router-link>
				<router-link
					to="/bug-bounty"
					class="navbar-item"
					:title="$t('BUG_BOUNTY')"
				>
					<div class="text">
						<p class="link-font">
							{{ $t("BUG_BOUNTY") }}
						</p>
					</div>
				</router-link>
				<!-- <router-link
					to="/affiliates"
					class="navbar-item"
					:title="$t('AFFILIATE_PROGRAM')"
				>
					<div class="text">
						<p class="link-font">
							{{ $t("AFFILIATE_PROGRAM") }}
						</p>
					</div>
				</router-link> -->
			</div>

			<div class="column is-paddingless is-1" />

			<div class="column is-paddingless is-8">
				<p class="has-text-weight-bold">
					{{ $t("DEMOCRATIZING_TRADING") }}
				</p>
				<p>
					{{ $t("featured_in") }}
					<a
						href="https://www.forbes.com/sites/yoavvilner/2019/05/22/whats-keeping-open-finance-from-competing-with-institutional-products/"
						title="Forbes"
						class="transition-faster"
						rel="noopener noreferrer nofollow"
						target="_blank"
						>Forbes</a
					>,
					<a
						href="https://www.entrepreneur.com/article/335944"
						title="Entrepreneur"
						class="transition-faster"
						rel="noopener noreferrer nofollow"
						target="_blank"
						>Entrepreneur</a
					>,
					<a
						href="https://www.benzinga.com/fintech/21/02/19742212/fintech-spotlight-how-morpher-lets-you-buy-apple-on-a-weekend-with-as-little-as-1"
						title="Benzinga"
						class="transition-faster"
						rel="noopener noreferrer nofollow"
						target="_blank"
						>Benzinga</a
					>,
					<a
						href="https://www.coindesk.com/policy/2020/12/01/why-europe-bests-the-us-at-attracting-crypto-startups/"
						title="CoinDesk"
						class="transition-faster"
						rel="noopener noreferrer nofollow"
						target="_blank"
						>CoinDesk</a
					>
					&
					<a
						href="https://cointelegraph.com/news/morpher-labs-attempts-to-crack-knotty-prediction-market-problems"
						title="Cointelegraph"
						class="transition-faster"
						rel="noopener noreferrer nofollow"
						target="_blank"
						>Cointelegraph</a
					>.
				</p>
				<p class="has-text-weight-bold mt-20">{{ $t("JOIN_US") }}</p>
				<p>
					{{ $t("HIRING_ON") }}
					<a
						href="https://angel.co/company/morpher/jobs"
						title="AngelList"
						class="transition-faster"
						rel="noopener noreferrer nofollow"
						target="_blank"
						>AngelList</a
					>,
					<a
						href="https://www.linkedin.com/company/morpher/jobs/"
						title="LinkedIn Jobs"
						class="transition-faster"
						rel="noopener noreferrer nofollow"
						target="_blank"
						>LinkedIn</a
					>, <span v-html="$t('HIRING_CONTACT')" />.<br />{{ $t("RATED_TOP") }}
				</p>
				<div class="is-size-14 mt-10">
					<p>{{ $t("MORPHER_BASED_IN") }}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "NavDropdown",
	props: {
		type: {
			type: String,
			required: true,
		},
		whitepaperLink: {
			type: String,
			required: false,
		},
	},
	data() {
		return {};
	},
};
</script>

<style scoped>
.dropdown-box {
	position: fixed;
	top: 64px;
	background-color: #fff;
	box-shadow: rgba(39, 47, 63, 0.13) 0 6px 14px -3px;
	z-index: 20;
	border-radius: 10px;
	padding: 10px 10px;
	max-width: 300px;
}

.navbar-item a,
a.navbar-item {
	flex-direction: column;
	align-items: flex-start;
	color: #444;
	transition-delay: 0s;
	transition-duration: 0.1s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.navbar-item a:hover,
a.navbar-item:hover {
	color: #000000;
	/* color: #228cdb; */
	transition-delay: 0s;
	transition-duration: 0.1s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.link-font {
	font-size: 18px;
	font-weight: 600;
}

.link-icon {
	margin-right: 2px;
	font-size: 14px;
}
.link-icon.is-blue {
	color: #228cdb;
}

.is-size-14 {
	font-size: 14px;
}

.dropdown-box .columns:not(:first-child) {
	margin-top: -2.25rem;
}

.nav-dropdowns > div {
	max-width: 1344px;
	margin: 0 auto;
	display: flex;
	justify-content: flex-start;
	padding-left: 0.5rem;
}

.navbar-item.icon {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: auto;
	height: auto;
	justify-content: flex-start;
}

.navbar-item > .icon {
	margin-right: 10px;
	width: 40px;
	height: 40px;
	box-shadow: 0px 2px 6px 0 rgb(0 0 0 / 12%);
	border-radius: 10px;
	background: #fff;
	color: #00c386;
	transition-delay: 0s;
	transition-duration: 0.25s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.navbar-item:hover > .icon {
	box-shadow: 0px 4px 8px 0 rgb(0 0 0 / 15%);
	transition-delay: 0s;
	transition-duration: 0.2s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.grid {
	flex: 0 1 0%;
	display: grid;
	grid-template-columns: repeat(2, max-content);
	gap: 20px 20px;
	grid-auto-flow: row;
	justify-content: end;
	align-items: flex-start;
}

.grid .item {
	display: inline-flex;
	color: inherit;
	min-width: 145px;
}

.nav-menu-text {
	padding-left: 0.75rem;
	font-family: "Rubik", sans-serif;
	font-size: 18px;
}

.sub-text {
	font-size: 14px;
	line-height: 1rem;
}
.tiny-text {
	font-size: 11px;
	padding-left: 0.75rem;
	max-width: 400px;
	margin-top: 0.5rem;
}
.mt-10 {
	margin-top: 10px;
}
.mt-20 {
	margin-top: 20px;
}
.social-link {
	font-weight: 600;
	line-height: 1rem;
}

.whitepaper-links {
	font-weight: 600;
	font-size: 14px;
	margin-top: -0.5rem;
}
</style>
