var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.navbar && _vm.registerURL)?_c('div',[(_vm.miniBtn)?_c('button',{staticClass:"button mini-btn",class:{ 'is-primary': _vm.navbarSticky },domProps:{"textContent":_vm._s(_vm.$t(_vm.customText ? _vm.customText : 'airdrop_early_btn'))},on:{"click":_vm.signup}}):(_vm.navbarMobile)?_c('button',{staticClass:"nav-btn button is-primary is-mobile-btn",domProps:{"textContent":_vm._s(_vm.$t(_vm.customText ? _vm.customText : 'airdrop_early_btn'))},on:{"click":_vm.signup}}):_c('button',{staticClass:"nav-btn button",class:{ 'is-primary': _vm.navbarSticky },domProps:{"textContent":_vm._s(_vm.$t(_vm.customText ? _vm.customText : 'airdrop_early_btn'))},on:{"click":_vm.signup}})]):(_vm.blogButton)?_c('div',[_c('button',{staticClass:"cta-btn-blog button is-primary",domProps:{"textContent":_vm._s(
          _vm.customText
            ? _vm.$t(_vm.customText)
            : _vm.airdropPage
              ? _vm.$t('airdrop_email_btn')
              : _vm.$t('airdrop_early_btn')
        )},on:{"click":_vm.signup}})]):_c('div',{staticClass:"button-spacing"},[_c('button',{staticClass:"cta-btn button is-primary",domProps:{"textContent":_vm._s(
          _vm.customText
            ? _vm.$t(_vm.customText)
            : _vm.airdropPage
              ? _vm.$t('airdrop_email_btn')
              : _vm.$t('airdrop_early_btn')
        )},on:{"click":_vm.signup}})])])}
var staticRenderFns = []

export { render, staticRenderFns }