<template>
	<div>
		<div
			class="nav-wrapper"
			:class="{
				'is-sticky': stickyNav,
				'is-white': isOpen,
				'is-notify': showLocaleNotify(),
			}"
		>
			<nav class="navbar navbar-spacing">
				<div class="container">
					<div class="navbar-brand">
						<router-link class="navbar-item" to="/">
							<img src="@/assets/img/navbrand.svg" alt="Morpher Logo" />
						</router-link>
						<div
							v-if="!isUserLoggedInTradingApp()"
							class="is-hidden-desktop end-item"
						>
							<GetStartedButton
								navbar
								navbarMobile
								mini-btn
								:navbarSticky="stickyNav"
								:class="{ 'is-hidden': isOpen }"
							/>
						</div>
						<a
							role="button"
							class="navbar-burger"
							data-target="navMenu"
							aria-label="menu"
							aria-expanded="false"
							:class="{ 'is-active': isOpen }"
							@click="isOpen = !isOpen"
						>
							<span aria-hidden="true" />
							<span aria-hidden="true" />
							<span aria-hidden="true" />
						</a>
					</div>

					<!-- Mobile Menu Items -->
					<div
						class="navbar-menu is-hidden-desktop"
						:class="{ 'is-active': isOpen }"
					>
						<div class="navbar-end">
							<div v-if="!isUserLoggedInTradingApp()">
								<router-link
									class="navbar-item is-size-5 has-text-centered mb-5px"
									to="/login"
									>{{ $t("LOG_IN") }}</router-link
								>
								<GetStartedButton
									navbar
									navbarMobile
									class="is-mobile-end-btn"
								/>
							</div>
							<div v-else>
								<GetStartedButton
									navbar
									navbarMobile
									class="is-mobile-end-btn"
									customText="open_morpher"
									:userAlreadyLoggedIn="isUserLoggedInTradingApp()"
								/>
							</div>
							<hr style="marginBottom: 10px" />
							<!-- Blog (if on blog page) -->
							<div v-if="blogCategories" id="blog-mobile" class="navbar-item">
								<a
									:class="{
										'navbar-link is-size-5': true,
										active: mobDropdown === 'blog',
									}"
									@click="changeActiveMobile('blog')"
								>
									{{ $t("BLOG") }}
								</a>
								<div
									v-show="mobDropdown === 'blog'"
									class="navbar-dropdown is-size-6"
								>
									<div class="item">
										<a
											href="/blog"
											class="navbar-item"
											:class="{ 'is-active': currentCategory === 'all' }"
										>
											{{ $t("ALL_POSTS") }}
										</a>
									</div>
									<div
										v-for="category in blogCategories"
										:key="category.slug"
										class="item"
									>
										<a
											class="navbar-item"
											:class="{
												'is-active': category.slug === currentCategory,
											}"
											:href="'/blog/category/' + category.slug"
											>{{ category.name }}</a
										>
									</div>
								</div>
							</div>
							<!-- Products -->
							<div id="product-mobile" class="navbar-item">
								<a
									:class="{
										'navbar-link is-size-5': true,
										active: mobDropdown === 'product',
									}"
									@click="changeActiveMobile('product')"
								>
									{{ $t("PRODUCTS") }}
								</a>
								<div
									v-show="mobDropdown === 'product'"
									class="navbar-dropdown is-size-6"
								>
									<div class="item">
										<router-link
											to="/register"
											class="navbar-item icon"
											:title="$t('TRADE')"
										>
											<div class="icon">
												<img
													src="@/assets/img/navbar/trade_logo.svg"
													alt="Morpher Trade Logo"
												/>
											</div>
											<div class="text">
												<p>{{ $t("TRADE") }}</p>
												<p class="sub-text">
													{{ $t("TRADE_DESC") }}
												</p>
											</div>
										</router-link>
									</div>
									<div class="item">
										<router-link
											to="/wallet"
											class="navbar-item icon"
											:title="$t('WALLET')"
										>
											<div class="icon">
												<b-icon
													icon="fas fa-wallet"
													pack="fas"
													class="link-icon has-text-blue"
												/>
											</div>
											<div class="text">
												<p>{{ $t("WALLET") }}</p>
												<p class="sub-text">
													{{ $t("WALLET_DESC") }}
												</p>
											</div>
										</router-link>
									</div>
									<!-- <div class="item">
										<router-link
											to="/arena"
											class="navbar-item icon"
											:title="$t('ARENA')"
										>
											<div class="icon">
												<img
													src="@/assets/img/navbar/eth_dex_logo.svg"
													alt="Morpher DEX Logo"
												/>
											</div>
											<div class="text">
												<p>{{ $t("ARENA") }}</p>
												<p class="sub-text">
													{{ $t("ARENA_DESC") }}
												</p>
											</div>
										</router-link>
									</div> -->
									<!-- Blockchain Tools -->
									<hr class="mobile-menu-hr" />
									<p class="mobile-menu-title">{{ $t("BLOCKCHAIN_TOOLS") }}</p>
									<div class="item">
										<router-link
											to="/stats"
											class="navbar-item icon"
											:title="$t('MPH_PROTOCOL_STATS')"
										>
											<div class="icon">
												<b-icon
													icon="fas fa-chart-bar"
													pack="fas"
													class="link-icon"
												/>
											</div>
											<div class="text">
												<p>
													{{ $t("MPH_PROTOCOL_STATS") }}
												</p>
												<p class="sub-text">
													{{ $t("MPH_PROTOCOL_STATS_DESC") }}
												</p>
											</div>
										</router-link>
									</div>
									<div class="item">
										<router-link
											to="/scan"
											class="navbar-item icon"
											:title="$t('SIDECHAIN_EXPLORER')"
										>
											<div class="icon">
												<b-icon
													icon="fas fa-dice-d6"
													pack="fas"
													class="link-icon"
												/>
											</div>
											<div class="text">
												<p>{{ $t("SIDECHAIN_EXPLORER") }}</p>
												<p class="sub-text">
													{{ $t("SIDECHAIN_EXPLORER_DESC") }}
												</p>
											</div>
										</router-link>
									</div>
									<!-- Disclaimer -->
									<div class="tiny-text">
										<p>
											<b-icon
												icon="fas fa-info-circle"
												pack="fas"
												size="is-small"
											/>
											{{ $t("STOCKS_MARKETS_DISCLAMER") }}
										</p>
									</div>
								</div>
							</div>
							<!-- Token -->
							<div id="token-mobile" class="navbar-item">
								<a
									:class="{
										'navbar-link is-size-5': true,
										active: mobDropdown === 'token',
									}"
									@click="changeActiveMobile('token')"
								>
									<span class="notranslate" translate="no">MPH</span>
								</a>
								<div
									v-show="mobDropdown === 'token'"
									class="navbar-dropdown is-size-6"
								>
									<!-- <div class="item">
										<router-link
											to="/airdrop"
											class="navbar-item icon"
											:title="$t('AIRDROP')"
										>
											<div class="icon">
												<b-icon
													icon="fas fa-parachute-box"
													pack="fas"
													class="link-icon"
												/>
											</div>
											<div class="text">
												<p>{{ $t("AIRDROP") }}</p>
												<p class="sub-text" v-html="$t('AIRDROP_DESC')" />
											</div>
										</router-link>
									</div> -->
									<div class="item">
										<a
											:href="$t('WHAT_IS_MPH_SUPPORT_LINK')"
											class="navbar-item icon"
											:title="$t('WHAT_IS_MPH')"
										>
											<div class="icon">
												<b-icon
													icon="fab fa-ethereum"
													pack="fab"
													class="link-icon"
												/>
											</div>
											<div class="text">
												<p v-html="$t('WHAT_IS_MPH')" />
												<p class="sub-text">
													{{ $t("WHAT_IS_MPH_DESC") }}
												</p>
											</div>
										</a>
									</div>
									<div class="item">
										<a
											:href="$t('TOKENOMICS_SUPPORT_LINK')"
											class="navbar-item icon"
											:title="$t('TOKENOMICS')"
										>
											<div class="icon">
												<b-icon
													icon="fas fa-coins"
													pack="fas"
													class="link-icon"
												/>
											</div>
											<div class="text">
												<p class="link-font">
													{{ $t("TOKENOMICS") }}
												</p>
												<p class="sub-text">
													{{ $t("TOKENOMICS_DESC") }}
												</p>
											</div>
										</a>
									</div>
									<!-- Exchanges -->
									<hr class="mobile-menu-hr" />
									<p class="mobile-menu-title">{{ $t("EXCHANGES") }}</p>
									<div class="item">
										<a
											href="https://v2.info.uniswap.org/token/0x6369c3dadfc00054a42ba8b2c09c48131dd4aa38"
											class="navbar-item icon"
											target="_blank"
											rel="nofollow noopener noreferrer"
											:title="$t('UNISWAP')"
										>
											<div class="icon">
												<img
													src="@/assets/img/navbar/uniswap.svg"
													alt="Uniswap Logo"
												/>
											</div>
											<div class="text">
												<p class="link-font">
													{{ $t("UNISWAP") }}
												</p>
												<p class="sub-text" v-html="$t('UNISWAP_DESC')" />
											</div>
										</a>
									</div>
									<div class="item">
										<a
											href="https://www.gate.io/trade/MPH_USDT"
											class="navbar-item icon"
											target="_blank"
											rel="nofollow noopener noreferrer"
											:title="$t('GATEIO')"
										>
											<div class="icon">
												<img
													src="@/assets/img/navbar/gateio.svg"
													alt="Gate.io Logo"
												/>
											</div>
											<div class="text">
												<p class="link-font">
													{{ $t("GATEIO") }}
												</p>
												<p class="sub-text" v-html="$t('GATEIO_DESC')" />
											</div>
										</a>
									</div>
									<div class="item">
										<a
											href="https://coinmarketcap.com/currencies/morpher/"
											target="_blank"
											class="navbar-item icon"
											rel="noopener noreferrer"
											:title="$t('COINMAKRETCAP')"
										>
											<div class="icon">
												<img
													src="@/assets/img/navbar/coinmarketcap.svg"
													alt="CoinMarkteCap Logo"
												/>
											</div>
											<div class="text">
												<p class="link-font">
													{{ $t("COINMAKRETCAP") }}
												</p>
												<p class="sub-text" v-html="$t('COINMAKRETCAP_DESC')" />
											</div>
										</a>
									</div>
								</div>
							</div>
							<!-- Learn -->
							<div id="learn-mobile" class="navbar-item">
								<a
									:class="{
										'navbar-link is-size-5': true,
										active: mobDropdown === 'learn',
									}"
									@click="changeActiveMobile('learn')"
								>
									{{ $t("LEARN") }}
								</a>
								<div
									v-show="mobDropdown === 'learn'"
									class="navbar-dropdown is-size-6"
								>
									<!-- <div class="item textflow">
										<router-link
											to="/whats-new"
											class="navbar-item"
											:title="$t('CHANGELOG_TITLE')"
										>
											<div class="text">
												<p>
													{{ $t("CHANGELOG_TITLE") }}
												</p>
												<p class="sub-text">
													{{ $t("CHANGELOG_NAV_DESC") }}
												</p>
											</div>
										</router-link>
									</div> -->
									<div class="item textflow">
										<a
											href="/blog"
											class="navbar-item text"
											:title="$t('BLOG')"
										>
											<div class="text">
												<p class="link-font">
													{{ $t("BLOG") }}
												</p>
												<p class="sub-text">
													{{ $t("BLOG_DESC") }}
												</p>
											</div>
										</a>
									</div>
									<div class="item textflow">
										<a
											:href="getWhitepaperLink()"
											class="navbar-item text"
											target="_blank"
											:title="$t('WHITEPAPER')"
										>
											<div class="text">
												<p class="link-font">
													{{ $t("WHITEPAPER") }}
												</p>
												<p class="sub-text">
													{{ $t("WHITEPAPER_DESC") }}
												</p>
											</div>
										</a>
									</div>
									<div class="whitepaper-links">
										<a href="/docs/morpher_whitepaper.pdf">
											English
										</a>
										|
										<a href="/docs/morpher_whitepaper_russian.pdf">
											Pусский
										</a>
										|
										<a href="/docs/morpher_whitepaper_chinese.pdf">
											普通话
										</a>
									</div>
									<hr class="mobile-menu-hr" />
									<div class="item textflow">
										<a
											href="https://support.morpher.com"
											class="navbar-item"
											:title="$t('HELP_CENTER')"
										>
											<div class="text">
												<p class="link-font">
													{{ $t("HELP_CENTER") }}
												</p>
												<p class="sub-text">
													{{ $t("HELP_CENTER_DESC") }}
												</p>
											</div>
										</a>
									</div>
									<div class="item textflow">
										<a
											:href="$t('GETTING_STARTED_SUPPORT_LINK')"
											class="navbar-item is-size-6"
											:title="$t('GETTING_STARTED')"
										>
											<div class="social-link">
												<p>
													{{ $t("GETTING_STARTED") }}
												</p>
											</div>
										</a>
									</div>
									<div class="item textflow">
										<a
											:href="$t('SUPPORTED_COUNTRIES_SUPPORT_LINK')"
											class="navbar-item is-size-6"
											:title="$t('SUPPORTED_COUNTRIES')"
										>
											<div class="social-link">
												<p>
													{{ $t("SUPPORTED_COUNTRIES") }}
												</p>
											</div>
										</a>
									</div>
									<div class="item textflow">
										<a
											:href="$t('ACCOUNT_AND_LOGIN_SUPPORT_LINK')"
											class="navbar-item is-size-6"
											:title="$t('ACCOUNT_AND_LOGIN')"
										>
											<div class="social-link">
												<p>
													{{ $t("ACCOUNT_AND_LOGIN") }}
												</p>
											</div>
										</a>
									</div>
								</div>
							</div>
							<!-- Community -->
							<div id="community-mobile" class="navbar-item">
								<a
									:class="{
										'navbar-link is-size-5': true,
										active: mobDropdown === 'community',
									}"
									@click="changeActiveMobile('community')"
								>
									{{ $t("COMMUNITY") }}
								</a>
								<div
									v-show="mobDropdown === 'community'"
									class="navbar-dropdown is-size-6"
								>
									<!-- <div class="item textflow">
										<a
											href="https://discord.gg/CBxuAhPSCj"
											class="navbar-item"
											target="_blank"
											rel="nofollow noopener noreferrer"
											:title="$t('DISCORD')"
										>
											<div class="social-link">
												<p>
													{{ $t("DISCORD") }}
												</p>
											</div>
										</a>
									</div> -->
									<div class="item textflow">
										<a
											href="https://twitter.com/morpher_io"
											class="navbar-item is-size-6"
											target="_blank"
											rel="nofollow noopener noreferrer"
											:title="$t('TWITTER')"
										>
											<div class="social-link">
												<p>
													{{ $t("TWITTER") }}
												</p>
											</div>
										</a>
									</div>
									<!-- <div class="item textflow">
										<a
											href="https://vk.com/morpher"
											class="navbar-item is-size-6"
											target="_blank"
											rel="nofollow noopener noreferrer"
											:title="$t('VK')"
										>
											<div class="social-link">
												<p>
													{{ $t("VK") }}
												</p>
											</div>
										</a>
									</div> -->
									<div class="item textflow">
										<a
											href="https://www.linkedin.com/company/morpher"
											target="_blank"
											rel="noopener noreferrer"
											:title="$t('LINKEDIN')"
											class="navbar-item"
										>
											<div class="text">
												<p>
													{{ $t("LINKEDIN") }}
												</p>
											</div>
										</a>
									</div>
									<div class="item textflow">
										<a
											href="https://github.com/Morpher-io/"
											target="_blank"
											rel="noopener noreferrer"
											:title="$t('GITHUB')"
											class="navbar-item"
										>
											<div class="text">
												<p>
													{{ $t("GITHUB") }}
												</p>
											</div>
										</a>
									</div>
								</div>
							</div>
							<!-- Company -->
							<div id="company-mobile" class="navbar-item">
								<a
									:class="{
										'navbar-link is-size-5': true,
										active: mobDropdown === 'company',
									}"
									@click="changeActiveMobile('company')"
								>
									{{ $t("COMPANY") }}
								</a>
								<div
									v-show="mobDropdown === 'company'"
									class="navbar-dropdown is-size-6"
								>
									<div class="item textflow">
										<router-link
											to="/about"
											class="navbar-item"
											:title="$t('ABOUT_US')"
										>
											<div class="text">
												<p>
													{{ $t("ABOUT_US") }}
												</p>
											</div>
										</router-link>
									</div>
									<div class="item textflow">
										<router-link
											to="/bug-bounty"
											class="navbar-item"
											:title="$t('BUG_BOUNTY')"
										>
											<div class="text">
												<p>
													{{ $t("BUG_BOUNTY") }}
												</p>
											</div>
										</router-link>
									</div>
									<!-- <div class="item textflow">
										<router-link
											to="/affiliates"
											class="navbar-item"
											:title="$t('AFFILIATE_PROGRAM')"
										>
											<div class="text">
												<p>
													{{ $t("AFFILIATE_PROGRAM") }}
												</p>
											</div>
										</router-link>
									</div> -->

									<!-- Company Info -->
									<hr class="mobile-menu-hr" />
									<p class="has-text-weight-bold">
										{{ $t("DEMOCRATIZING_TRADING") }}
									</p>
									<p>
										{{ $t("featured_in") }}
										<a
											href="https://www.forbes.com/sites/yoavvilner/2019/05/22/whats-keeping-open-finance-from-competing-with-institutional-products/"
											title="Forbes"
											class="transition-faster"
											rel="noopener noreferrer nofollow"
											target="_blank"
											>Forbes</a
										>,
										<a
											href="https://www.entrepreneur.com/article/335944"
											title="Entrepreneur"
											class="transition-faster"
											rel="noopener noreferrer nofollow"
											target="_blank"
											>Entrepreneur</a
										>,
										<a
											href="https://www.benzinga.com/fintech/21/02/19742212/fintech-spotlight-how-morpher-lets-you-buy-apple-on-a-weekend-with-as-little-as-1"
											title="Benzinga"
											class="transition-faster"
											rel="noopener noreferrer nofollow"
											target="_blank"
											>Benzinga</a
										>,
										<a
											href="https://www.coindesk.com/policy/2020/12/01/why-europe-bests-the-us-at-attracting-crypto-startups/"
											title="CoinDesk"
											class="transition-faster"
											rel="noopener noreferrer nofollow"
											target="_blank"
											>CoinDesk</a
										>
										&
										<a
											href="https://cointelegraph.com/news/morpher-labs-attempts-to-crack-knotty-prediction-market-problems"
											title="Cointelegraph"
											class="transition-faster"
											rel="noopener noreferrer nofollow"
											target="_blank"
											>Cointelegraph</a
										>.
									</p>
									<br />
									<p class="has-text-weight-bold">{{ $t("JOIN_US") }}</p>
									<p>
										{{ $t("HIRING_ON") }}
										<a
											href="https://angel.co/company/morpher/jobs"
											title="AngelList"
											class="transition-faster"
											rel="noopener noreferrer nofollow"
											target="_blank"
											>AngelList</a
										>,
										<a
											href="https://www.linkedin.com/company/morpher/jobs/"
											title="LinkedIn Jobs"
											class="transition-faster"
											rel="noopener noreferrer nofollow"
											target="_blank"
											>LinkedIn</a
										>, <span v-html="$t('HIRING_CONTACT')" />.<br />{{
											$t("RATED_TOP")
										}}
									</p>
									<br />
									<div class="is-size-14">
										<p>
											{{ $t("MORPHER_BASED_IN") }}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<!-- Menu Items in Navbar -->
					<div class="navbar-start is-hidden-touch">
						<transition>
							<div
								v-show="hover.product"
								@mouseover="hover.product = true"
								@mouseleave="hover.product = false"
							>
								<NavDropdown type="product" />
							</div>
						</transition>
						<a
							class="navbar-item "
							@mouseover="hover.product = true"
							@mouseleave="hover.product = false"
						>
							{{ $t("PRODUCTS") }}
						</a>

						<transition>
							<div
								v-show="hover.token"
								@mouseover="hover.token = true"
								@mouseleave="hover.token = false"
							>
								<NavDropdown type="token" />
							</div>
						</transition>
						<a
							class="navbar-item "
							@mouseover="hover.token = true"
							@mouseleave="hover.token = false"
						>
							<span class="notranslate" translate="no">MPH</span>
						</a>

						<transition>
							<div
								v-show="hover.learn"
								@mouseover="hover.learn = true"
								@mouseleave="hover.learn = false"
							>
								<NavDropdown
									type="learn"
									:whitepaperLink="getWhitepaperLink()"
								/>
							</div>
						</transition>
						<a
							class="navbar-item "
							@mouseover="hover.learn = true"
							@mouseleave="hover.learn = false"
						>
							{{ $t("LEARN") }}
						</a>

						<transition>
							<div
								v-show="hover.company"
								@mouseover="hover.company = true"
								@mouseleave="hover.company = false"
							>
								<NavDropdown type="company" />
							</div>
						</transition>
						<a
							class="navbar-item "
							@mouseover="hover.company = true"
							@mouseleave="hover.company = false"
						>
							{{ $t("COMPANY") }}
						</a>
					</div>

					<!-- Menu Items End of Navbar / CTA Section -->
					<div class="navbar-end is-hidden-touch">
						<div
							v-show="!stickyNav && !isUserLoggedInTradingApp()"
							class="navbar-item hide-login"
						>
							<router-link to="/login">{{ $t("LOG_IN") }}</router-link>
						</div>
						<!-- Helper text for people to want to click CTA BTN -->
						<!-- <div
							v-show="stickyNav && !isUserLoggedInTradingApp()"
							class="navbar-item has-text-weight-semibold hide-cta-msg is-hidden-desktop-only"
						>
							{{ cta_message }}
							<b-icon
								icon="fas fa-long-arrow-alt-right"
								pack="fas"
								style="marginLeft: 5px; marginRight: -10px"
								class="no-background"
							/>
						</div> -->
						<div class="navbar-item">
							<GetStartedButton
								navbar
								:navbarSticky="stickyNav"
								:customText="isUserLoggedInTradingApp() ? 'open_morpher' : ''"
								:userAlreadyLoggedIn="isUserLoggedInTradingApp()"
							/>
						</div>
					</div>
				</div>
			</nav>

			<!-- Blog mini navbar below regular navbar for blog categories -->
			<nav v-if="blogCategories" class="blog-nav-wrapper is-hidden-touch">
				<div class="container">
					<div class="tabs">
						<ul>
							<li :class="{ 'is-active': currentCategory === 'all' }">
								<a href="/blog">All Posts</a>
							</li>
							<li
								v-for="category in blogCategories"
								:key="category.slug"
								:class="{ 'is-active': category.slug === currentCategory }"
							>
								<a :href="'/blog/category/' + category.slug">{{
									category.name
								}}</a>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</div>
		<!-- Empty Div to maintain spacing as navbar goes from relative to fixed position -->
		<div
			class="reg-pholder"
			:class="{ 'blog-pholder': blogCategories, 'is-sticky': stickyNav }"
		/>
	</div>
</template>

<script>
import { i18n } from "@/plugins/i18n";
import GetStartedButton from "@/components/partials/GetStartedButton";
import NavDropdown from "@/components/partials/NavDropdown";
import { isUserLoggedInTradingApp } from "@/utils/helpers";

export default {
	name: "Navbar",
	components: {
		GetStartedButton,
		NavDropdown,
	},
	props: {
		blogCategories: {
			type: Object,
			required: false,
		},
		currentCategory: {
			type: String,
			required: false,
		},
	},
	data() {
		return {
			isOpen: false,
			stickyNav: false,
			oldScrollPos: 0,
			cta_message: i18n.t("navbar_cta_p"),
			hover: { product: false, token: false, learn: false, company: false },
			mobDropdown: "",
			browserLocale:
				(navigator.languages && navigator.languages[0]) ||
				navigator.language ||
				"",
		};
	},
	// async created() {
	//   // Get the current airdrop values for display
	//   utils.getAirdropRewards(this.cta_message).then(result => {
	//     this.cta_message = result;
	//   });
	// },
	mounted() {
		window.addEventListener("scroll", this.onScroll);
		window.addEventListener("resize", this.onResize);
	},
	beforeDestroy() {
		window.removeEventListener("scroll", this.onScroll);
		window.removeEventListener("resize", this.onResize);
	},
	methods: {
		onScroll() {
			// Check if user hovering over any navbar element at the time
			const hovering = Object.values(this.hover).some((e) => e);

			// Scroll Thresholds
			const scrollDown = 100;
			const scrollUp = 10;
			const scrollBlogUp = 40;
			const scrollBlogDown = scrollBlogUp / 2;

			const scrollPos =
				window.pageYOffset || document.documentElement.scrollTop;

			// Mobile
			if (this.isOpen) {
				this.stickyNav = true;
				this.oldScrollPos = scrollPos;
				return;
			}

			if (scrollPos < scrollUp) {
				this.stickyNav = false;
			} else if (hovering) {
				this.stickyNav = true;
			} else if (this.blogCategories) {
				// If blog page, activate stickybar only on scroll up and deactivate again on scroll down
				if (!this.stickyNav && this.oldScrollPos - scrollPos > scrollBlogUp) {
					this.stickyNav = true;
				} else if (
					this.stickyNav &&
					scrollPos - this.oldScrollPos > scrollBlogDown
				) {
					this.stickyNav = false;
				}
			} else if (scrollPos > scrollDown) {
				this.stickyNav = true;
			}
			// Record current scroll position for blog logic
			this.oldScrollPos = scrollPos;
		},
		clicked() {
			// Record Google Analytics Event

			this.$gtag.event("website_navigation", {
				event_category: "navbar_click",
				event_label: "get_started",
			});
		},
		onResize() {
			// If screen above 768px, make sure isOpen is false
			if (window.innerWidth > 1024) this.isOpen = false;
		},
		isUserLoggedInTradingApp,
		changeActiveMobile(type) {
			if (type === this.mobDropdown) {
				this.mobDropdown = "";
			} else {
				this.mobDropdown = type;
			}
		},
		showLocaleNotify() {
			const lang = (
				(this.browserLocale ? this.browserLocale.split("-")[0] : "") || ""
			).toUpperCase();
			const supportedLocales = JSON.parse(
				process.env.VUE_APP_I18N_SUPPORTED_LOCALE
			) || ["en"];
			if (!supportedLocales.includes(lang.toLowerCase())) return false;
			return lang !== this.$i18n.locale.toUpperCase();
		},
		getWhitepaperLink() {
			const lang = this.$i18n.locale;
			if (lang === "ru") {
				return "/docs/morpher_whitepaper_russian.pdf";
			} else if (lang === "zh") {
				return "/docs/morpher_whitepaper_chinese.pdf";
			} else {
				return "/docs/morpher_whitepaper.pdf";
			}
		},
	},
};
</script>

<style scoped>
.nav-wrapper.is-sticky {
	position: fixed;
	background-color: #fff;
	top: 0;
	width: 100%;
	z-index: 100;
	box-shadow: 0 10px 16px -10px rgba(39, 47, 63, 0.1);
}
.nav-wrapper.is-white {
	background-color: #fff;
}
.nav-wrapper.is-notify .navbar .dropdown-box {
	top: 104px !important;
}
.nav-wrapper.is-notify.is-sticky .navbar .dropdown-box {
	top: 70px !important;
}
.navbar-spacing {
	padding-top: 10px;
	padding-bottom: 10px;
}
.navbar-item a,
a.navbar-item {
	font-weight: 600;
	color: #4c4c4c;
	transition-delay: 0s;
	transition-duration: 0.2s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.navbar-item a:hover,
a.navbar-item:hover {
	color: #000000;
	transition-delay: 0s;
	transition-duration: 0.2s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.end-item {
	display: flex;
	align-items: center;
	margin-left: calc(100% - 356px);
}
@media only screen and (max-width: 360px) {
	.end-item {
		display: none;
	}
}

.is-mobile-end {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.is-mobile-end-btn {
	padding-left: calc(5%);
	padding-right: calc(5%);
}

/* Mobile Menu Styling */
div.navbar-menu.is-hidden-desktop.is-active {
	/* box-shadow: 0 8px 16px -8px rgba(182, 191, 210, 0.6); */
	box-shadow: 0 10px 16px -10px rgba(39, 47, 63, 0.15);
	position: absolute;
	width: 100%;
	height: 100vh;
	overflow-y: auto;
	position: fixed;
	padding-bottom: 100px;
	left: 0;
}

.mob-link-icon {
	margin-right: 5px;
	font-size: 14px;
}

/* Hamburger Menu Mobile Styling */
.navbar-burger span {
	height: 3px;
	width: 24px;
	border-radius: 3px;
	left: calc(50% - 12px);
}
.navbar-burger span:nth-child(1) {
	top: calc(50% - 9px);
}
.navbar-burger span:nth-child(2) {
	top: calc(50% - 1px);
}
.navbar-burger span:nth-child(3) {
	top: calc(50% + 7px);
}
.navbar-burger.is-active span:nth-child(1) {
	-webkit-transform: translateY(8px) rotate(45deg);
	transform: translateY(8px) rotate(45deg);
}
.navbar-burger.is-active span:nth-child(3) {
	-webkit-transform: translateY(-8px) rotate(-45deg);
	transform: translateY(-8px) rotate(-45deg);
}

.fade-enter-active,
.fade-leave-active {
	transition-delay: 0.025s;
	transition-duration: 0.15s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

/* Blog Styling */
.blog-nav-wrapper {
	background: #fff;
	width: 100%;
	line-height: 45px;
}
.blog-nav-wrapper .tabs {
	background: #fff;
}
.blog-nav-wrapper .tabs a {
	padding-bottom: 0;
	padding-top: 0;
	border-bottom-color: transparent;
}
.blog-nav-wrapper .tabs ul {
	border-bottom: none;
}
.blog-nav-wrapper .tabs ul li a {
	color: #4c4c4c;
	font-weight: 500;
}
.blog-nav-wrapper .tabs ul li:hover a {
	color: #000000;
}
.blog-nav-wrapper .tabs ul li.is-active a {
	color: #000000;
}
.reg-pholder.is-sticky {
	height: 72px;
}
.blog-pholder.is-sticky {
	height: 118px;
}
@media only screen and (min-width: 1100px) {
	.blog-nav-wrapper .tabs {
		margin-left: -0.75rem;
	}
}
@media only screen and (max-width: 1100px) {
	.hide-cta-msg {
		display: none;
	}
}
@media only screen and (max-width: 840px) {
	.hide-login {
		display: none;
	}
}
@media only screen and (max-width: 1024px) {
	.blog-pholder.is-sticky {
		height: inherit;
	}
}

/* Screen sizing bug at 1024px...hamburger menu doesn't show up */
@media only screen and (width: 1024px) {
	.is-hidden-touch {
		display: inherit !important;
	}
	.is-hidden-desktop {
		display: none !important;
	}
}

.navbar .dropdown-box {
	width: 100%;
	top: 70px !important;
	left: 0;
	right: 0;
	border-radius: 0 !important;
	padding: 40px 45px !important;
	max-width: none !important;
	border-top: 1px solid #f3e8e8;
}

.navbar-spacing {
	padding: 0;
	height: 70px;
}

.navbar-brand {
	display: flex;
	align-items: center;
	flex: 0 0 auto;
	height: 100%;
}

.navbar-start {
	display: flex;
	flex: 1;
	/* justify-content: center; */
	justify-content: flex-start;
	margin-left: 20px;
}

/* .navbar-end {
	display: flex;
	flex: 2 1 0%;
	justify-content: flex-end;
	align-items: center;
} */

.mb-5px {
	margin-bottom: 5px !important;
}

a.navbar-item {
	margin: 0;
	padding: 0px 18px;
}

.navbar-start a.navbar-item::before {
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	height: 0;
	background: #00c386;
	transition: 200ms;
	margin: 0px 18px;
}

.navbar-start a.navbar-item:hover::before {
	height: 3px;
}

@media screen and (min-width: 768px) {
	.navbar-menu {
		display: none;
	}

	.navbar-burger {
		display: block;
	}

	.navbar-dropdown {
		background: none;
		border-radius: 0;
		border: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		position: relative;
		display: block;
	}

	.navbar-item {
		display: block;
	}
}

@media screen and (min-width: 1024px) {
	.navbar-burger {
		display: none;
	}

	.navbar,
	.navbar-menu,
	.navbar-start,
	.navbar-end {
		align-items: stretch;
		display: flex;
	}

	.navbar-dropdown {
		background-color: white;
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
		border-top: 2px solid #dbdbdb;
		-webkit-box-shadow: 0 8px 8px rgb(10 10 10 / 10%);
		box-shadow: 0 8px 8px rgb(10 10 10 / 10%);
		display: none;
		font-size: 0.875rem;
		left: 0;
		min-width: 100%;
		position: absolute;
		top: 100%;
		z-index: 20;
	}

	.navbar-item {
		display: flex;
	}
}

@media screen and (max-width: 1023px) {
	.navbar-menu {
		background-color: white;
		-webkit-box-shadow: 0 8px 16px rgb(10 10 10 / 10%);
		box-shadow: 0 8px 16px rgb(10 10 10 / 10%);
		padding: 0.5rem 0;
	}

	.navbar-menu.is-active {
		display: block;
	}

	.navbar > .container {
		display: block;
		height: 100%;
	}

	.navbar-end {
		flex-direction: column;
	}

	.navbar-menu .navbar-item {
		padding: 0;
	}

	.navbar-menu .navbar-item .navbar-link {
		padding: 20px;
	}

	.navbar-menu .navbar-item .navbar-link::after {
		transition: 200ms;
	}

	.navbar-menu .navbar-item .navbar-link.active::after {
		transform: rotateZ(135deg);
	}

	.navbar-menu .navbar-dropdown {
		padding: 20px 24px;
		background: #faf8fd;
		border-top: 1px solid rgb(255, 255, 255);
		border-bottom: 1px solid rgb(255, 255, 255);
	}

	.navbar-item.item.textflow {
		display: block !important;
	}
}

.navbar-item .item {
	display: flex;
	align-items: center;
	width: 100%;
}
@media screen and (max-width: 1023px) {
	.navbar-item .item.textflow {
		display: block;
	}
}

.navbar-item .item > :not(.icon) {
	margin-left: 0px;
}

.navbar-item .item + .item,
.navbar-item .whitepaper-links + .item {
	margin-top: 1.25rem;
}

.navbar-item.text {
	widows: auto;
	height: auto;
	max-width: 100%;
}

.navbar-item.icon {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: auto;
	max-width: 100%;
	height: auto;
}

.navbar-item > .icon:not(.no-background) {
	margin-right: 10px;
	width: 40px;
	min-width: 40px;
	height: 40px;
	box-shadow: 0px 2px 6px 0 rgb(0 0 0 / 12%);
	border-radius: 10px;
	background: #fff;
	color: #00c386;
	transition-delay: 0s;
	transition-duration: 0.25s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.navbar-item:hover > .icon:not(.no-background) {
	box-shadow: 0px 4px 8px 0 rgb(0 0 0 / 15%);
	transition-delay: 0s;
	transition-duration: 0.2s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.sub-text {
	font-weight: 400;
	font-size: 14px;
	line-height: 1rem;
}
.whitepaper-links {
	font-size: 14px;
	margin-top: 0.25rem;
}

.mobile-menu-title {
	font-family: "Rubik", sans-serif;
	font-size: 18px;
	margin-bottom: 0.5rem;
}

.mobile-menu-hr {
	background-color: #d8d8d8;
	height: 1px;
	margin-top: 1.5rem;
	margin-bottom: 1rem;
}

.tiny-text {
	font-size: 11px;
	margin-top: 1.25rem;
}
</style>
